/**
 * /* The following line can be included in a src/App.scss
 *
 * @format
 */

@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: "Rubik-Regular";
  src: url("assets/fonts/Rubik-Regular.eot");
  src: local("☺"), url("assets/fonts/Rubik-Regular.woff") format("woff"),
    url("assets/fonts/Rubik-Regular.ttf") format("truetype"),
    url("assets/fonts/Rubik-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Rubik-Black";
  src: url("assets/fonts/Rubik-Black.eot");
  src: local("☺"), url("assets/fonts/Rubik-Black.woff") format("woff"),
    url("assets/fonts/Rubik-Black.ttf") format("truetype"),
    url("assets/fonts/Rubik-Black.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Rubik-BlackItalic";
  src: url("assets/fonts/Rubik-BlackItalic.eot");
  src: local("☺"), url("assets/fonts/Rubik-BlackItalic.woff") format("woff"),
    url("assets/fonts/Rubik-BlackItalic.ttf") format("truetype"),
    url("assets/fonts/Rubik-BlackItalic.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Rubik-Bold";
  src: url("assets/fonts/Rubik-Bold.eot");
  src: local("☺"), url("assets/fonts/Rubik-Bold.woff") format("woff"),
    url("assets/fonts/Rubik-Bold.ttf") format("truetype"),
    url("assets/fonts/Rubik-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Rubik-BoldItalic";
  src: url("assets/fonts/Rubik-BoldItalic.svg");
  src: local("☺"), url("assets/fonts/Rubik-BoldItalic.woff") format("woff"),
    url("assets/fonts/Rubik-BoldItalic.ttf") format("truetype"),
    url("assets/fonts/Rubik-BoldItalic.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Rubik-Italic";
  src: url("assets/fonts/Rubik-Italic.ttf");
  src: local("☺"), url("assets/fonts/Rubik-Italic.woff") format("woff"),
    url("assets/fonts/Rubik-Italic.ttf") format("truetype"),
    url("assets/fonts/Rubik-Italic.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Rubik-Light";
  src: url("assets/fonts/Rubik-Light.svg");
  src: local("☺"), url("assets/fonts/Rubik-Light.woff") format("woff"),
    url("assets/fonts/Rubik-Light.ttf") format("truetype"),
    url("assets/fonts/Rubik-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Rubik-Medium";
  src: url("assets/fonts/Rubik-Medium.svg");
  src: local("☺"), url("assets/fonts/Rubik-Medium.woff") format("woff"),
    url("assets/fonts/Rubik-Medium.ttf") format("truetype"),
    url("assets/fonts/Rubik-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");
:root {
  --rubikRegular: "Rubik-Regular";
  --rubikBlack: "Rubik-Black";
  --rubikBlackItalic: "Rubik-BlackItalic";
  --rubikBold: "Rubik-Bold";
  --rubikBoldItalic: "Rubik-BoldItalic";
  --rubikItalic: "Rubik-Italic";
  --rubikLight: "Rubik-Light";
  --rubikMedium: "Rubik-Medium";
  --white: #ffffff;
  --black: #000000;
  --lightGray: #f1f1f1;
  --lightGrayHover: #cbcbcb;
  --formColor: #939393;
  --statusBadgeDraft: #b7b7b7;
  --statusBadgeNew: #b537b2;
  --statusBadgeRejected: #e54b66;
  --statusBadgeReady: #01b6d3;
  --statusBadgeGray: #b7b7b7;
  --statusBadgeApproved: #05bb8f;
  --statusBadgeCall: linear-gradient(105.34deg, #b537b2 0%, #e15a65 100%);
  --gray700: #585858;
  --grdBtn: linear-gradient(105.34deg, #b537b2 0%, #e15a65 100%);
  --grdBtnHover: linear-gradient(105.34deg, #e15a65 0%, #b537b2 100%);
}
body[dir="rtl"],
body[dir="rtl"] * {
  font-family: "Tajawal", sans-serif !important;
}
body,
html {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  font-family: var(--rubikRegular);
  font-size: 16px;
  color: var(--black);
}
* {
  box-sizing: border-box;
}
img {
  display: inline-block;
}
// input[type='date']::-webkit-inner-spin-button,
// input[type='date']::-webkit-calendar-picker-indicator,
// input[type='time']::-webkit-inner-spin-button,
// input[type='time']::-webkit-calendar-picker-indicator {
//   display: none;
//   -webkit-appearance: none;
// }
input,
input.form-control,
select,
textarea,
textarea.form-control,
.form-select {
  font-size: 14px;
  color: var(--formColor);
  border: 2px solid var(--lightGray);
  width: 100%;
  border-radius: 100px;
  padding: 10px 18px;
  box-shadow: unset !important;
}
.form-select {
  color: var(--black);
}
.form-select:focus {
  border: 2px solid var(--black);
}
input[disabled="disabled"],
input.form-control[disabled="disabled"] {
  opacity: 0.4;
}
input[disabled="disabled"]::placeholder,
input.form-control[disabled="disabled"]::placeholder {
  opacity: 0.4;
}
input:focus,
input.form-control:focus,
select:focus,
textarea:focus {
  border: 2px solid var(--black);
}
// input.date[type='date'] {
//   background-image: url(./assets/images/dateBlackIcon.svg);
//   background-repeat: no-repeat;
//   background-position: calc(100% - 15px) center;
// }
// input[type='time'] {
//   background-image: url(./assets/images/timeIcon.svg);
//   background-repeat: no-repeat;
//   background-position: calc(100% - 15px) center;
// }
.noDataSec {
  text-align: center;
  background: #ffffff;
  margin-top: 40px;
  // box-shadow: 0px 0px 26px rgba(65, 3, 95, 0.09);
  // padding: 25px 25px;
  // border-radius: 22px;
  .title {
    font-size: 30px;
    margin-bottom: 30px;
  }
  img {
    width: 400px;
    height: auto;
  }
}
.csDatepicker {
  img {
    display: none;
  }
  // input {
  //   background-image: url(./assets/images/dateIcon.svg);
  //   background-repeat: no-repeat;
  //   background-position: calc(100% - 15px) center;
  // }
}
.fWrapper {
  .doubleRow {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    .formRow {
      width: 50%;
      padding: 0px 15px;
      margin-bottom: 20px;
    }
  }
}

// date-picker modal designs
.date-picker-modal {
  .label {
    margin-bottom: 10px;
  }
  .doubleRow {
    display: flex;
    width: 100%;
    gap: 20px;
  }
  .modal-btn {
    background: var(--grdBtn);
    color: var(--white);
    font-family: var(--rubikMedium);
    border: none;
    padding: 7px 32px;
    border-radius: 100px;
    transition: 0.2s;
    margin-bottom: 8px;
    &:hover {
      background: var(--grdBtnHover);
    }
  }
}

// header-start
.header {
  padding: 40px 0px;
  .lang {
    margin-right: 20px;
    button {
      background-color: transparent;
      border: 0px;
      padding: 0px;
      display: inline-flex;
      align-items: center;
      position: relative;
      top: 2px;
      color: var(--formColor);
      &.en {
        margin-right: 10px;
      }
      &.ar {
        margin-left: 10px;
      }
      img {
        width: 22px;
        position: relative;
        top: -2px;
        margin-right: 5px;
        filter: grayscale(1) brightness(2);
      }
      &.active {
        color: var(--black);
        img {
          filter: unset;
        }
      }
    }
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .logoWrapper {
    width: 225px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .rightWrapper {
    width: calc(100% - 225px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 10px;
    .userName {
      color: var(--black);
      font-size: 16px;
      margin-bottom: 0px;
      margin-right: 30px;
    }
    .notifyBtn {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background-color: var(--lightGray);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: none;
      transition: 0.5s;
      img {
        width: 18px;
      }
      &:hover {
        background-color: var(--lightGrayHover);
      }
    }
  }
}
// header-end

.appointmentsPage {
  margin-bottom: 60px;
}

.appointmentPHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    p {
      font-size: 20px;
      margin-bottom: 0px;
      margin-right: 18px;
    }
    .plusBtn {
      width: 44px;
      height: 44px;
      display: inline-flex;
      background-color: var(--lightGray);
      border-radius: 50%;
      border: none;
      position: relative;
      transition: 0.5s;
      &:hover {
        background-color: var(--lightGrayHover);
      }
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        background-color: var(--black);
      }
      &:before {
        width: 16px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 16px;
      }
    }
  }
  .right {
    width: 540px;
    max-width: 100%;
    padding-left: 30px;
    .fWrapper {
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
      .formRow {
        width: 33.3333%;
        padding: 0px 15px;
      }
      .form-select {
      }
    }
  }
}
.appointmentTabs {
  margin-top: 55px;
  .aTabs {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 20px;
      margin-bottom: 20px;
      &:last-child {
        margin-right: 0px;
      }
      button {
        font-size: 20px;
        color: var(--black);
        background-color: var(--lightGray);
        border: none;
        padding: 7px 22px;
        border-radius: 100px;
        transition: 0.2s;
        & > span {
          font-size: 14px;
        }
        &.active,
        &:hover {
          background: linear-gradient(105.34deg, #b537b2 0%, #e15a65 100%);
          color: var(--white);
          font-family: var(--rubikMedium);
        }
      }
    }
  }
  .aTabsContant {
    margin-top: 10px;
  }
}
.appointmentBox {
  background: var(--white);
  box-shadow: 0px 0px 26px rgba(65, 3, 95, 0.09);
  border-radius: 22px;
  padding: 26px;
  margin-bottom: 30px;
  .topHeader {
    display: flex;
    justify-content: space-between;
    .left {
      width: 100%;
      text-align: justify;
      padding-right: 30px;
      max-width: 100%;
      p {
        margin-bottom: 0px;
      }
      .title {
        font-size: 16px;
        font-family: var(--rubikBold);
        margin-bottom: 5px;
      }
    }
    .right {
      text-align: right;
      min-width: 95px;
      .statusBadge {
        font-size: 14px;
        padding: 5px 19px;
        display: inline-block;
        background-color: var(--statusBadgeGray);
        border-radius: 50px;
        color: var(--white);
        &.code1 {
          background-color: var(--statusBadgeDraft);
        }
        &.code2 {
          background-color: var(--statusBadgeNew);
        }
        &.code3 {
          background-color: var(--statusBadgeApproved);
        }
        &.code4 {
          background-color: var(--statusBadgeRejected);
        }
        &.code5 {
          background-color: var(--statusBadgeGray); // default
        }
        &.code6 {
          background-color: var(--statusBadgeReady);
        }
        &.code7 {
          background: var(--statusBadgeCall);
        }
        &.code8 {
          background-color: var(--statusBadgeGray); //default
        }
      }
      .idNumber {
        display: block;
        font-size: 14px;
        color: var(--gray700);
        margin-top: 15px;
        white-space: pre;
      }
    }
  }
  .fWrapper {
    margin-top: 15px;
    label {
      display: block;
      width: 100%;
      font-size: 16px;
      font-family: var(--rubikBold);
      margin-bottom: 5px;
    }
    .datetimeDBWrapper {
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  .apBoxBtns {
    margin-top: 10px;
    .apBoxBtn {
      font-size: 20px;
      background: var(--grdBtn);
      color: var(--white);
      font-family: var(--rubikMedium);
      border: none;
      padding: 7px 32px;
      border-radius: 100px;
      transition: 0.2s;
      margin-right: 30px;
      margin-bottom: 8px;
      &:last-child {
        margin-right: 0px;
      }
      &:hover {
        background: var(--grdBtnHover);
      }
    }
  }
}

.role-switcher {
  .modal-btn {
    background: var(--grdBtn);
    color: var(--white);
    font-family: var(--rubikMedium);
    border: none;
    padding: 7px 32px;
    border-radius: 100px;
    transition: 0.2s;
    margin-bottom: 8px;
    &:hover {
      background: var(--grdBtnHover);
    }
  }
}

.newAppoinmentForm {
  margin-top: 15px;
  .innerWrapper {
    width: 500px;
    max-width: 100%;
  }
  .input-group {
    margin-bottom: 20px;
  }
  .formTitle {
    font-size: 20px;
    position: relative;
    padding-left: 24px;
    margin-bottom: 20px;
    display: inline-block;
    cursor: pointer;
    &:before {
      content: "";
      position: absolute;
      top: 4px;
      left: 0px;
      transform: rotate(45deg);
      width: 13px;
      height: 14px;
      border-left: 2px solid var(--formColor);
      border-bottom: 2px solid var(--formColor);
    }
  }
  .reminderTitle {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .doubleRow {
    width: 420px;
    max-width: 100%;
  }
  .doubleRow > div {
    width: 50%;
    position: relative;
    .form-label {
      position: absolute;
      top: -8px;
      left: 30px;
      font-size: 12px;
      color: var(--formColor);
      margin-bottom: 0px;
      background-color: #fff;
      padding: 0px 4px;
    }
  }
  button[type="submit"] {
    font-size: 20px;
    background: var(--grdBtn);
    color: var(--white);
    font-family: var(--rubikMedium);
    border: none;
    padding: 7px 32px;
    border-radius: 100px;
    transition: 0.2s;
    margin-right: 30px;
    margin-bottom: 8px;
    margin-top: 40px;
    &:hover {
      background: var(--grdBtnHover);
    }
  }
}

body[dir="rtl"] .appointmentTabs .aTabs li button.active,
body[dir="rtl"] .appointmentTabs .aTabs li button:hover {
  font-weight: 600;
}
body[dir="rtl"] .appointmentBox .topHeader .left .title,
body[dir="rtl"] .appointmentBox .fWrapper label,
body[dir="rtl"] .newAppoinmentForm .reminderTitle,
body[dir="rtl"] .newAppoinmentForm button[type="submit"] {
  font-weight: 700;
}

@media (max-width: 991px) {
  .appointmentPHeader {
    .right {
      width: 500px;
      .fWrapper {
        margin-left: -10px;
        margin-right: -10px;
        .formRow {
          padding: 0px 10px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .appointmentsPage {
    margin-top: 20px;
  }
  .header {
    padding: 20px 0px;
    .logoWrapper {
      width: 150px;
    }
    .rightWrapper {
      width: calc(100% - 150px);
      .userName {
        margin-right: 15px;
      }
    }
  }
  .appointmentPHeader {
    flex-wrap: wrap;
    .left {
      .plusBtn {
        width: 38px;
        height: 38px;
      }
    }
    .right {
      width: 100%;
      padding-left: 0px;
      margin-top: 20px;
    }
  }
  .appointmentTabs {
    margin-top: 40px;
    .aTabs {
      li {
        margin-right: 10px;
        button {
          font-size: 16px;
        }
      }
    }
  }
  .fWrapper {
    .doubleRow {
      .datetimeDBWrapper {
        .formRow {
          width: 100%;
        }
      }
    }
  }
  .appointmentBox {
    .apBoxBtns {
      margin-top: 0px;
      .apBoxBtn {
        margin-right: 15px;
        font-size: 18px;
      }
    }
  }
}
@media (max-width: 575px) {
  input,
  input.form-control,
  select,
  textarea {
    padding: 7px 15px;
  }
  .fWrapper {
    .doubleRow {
      .formRow {
        width: 100%;
      }
    }
  }
  .header {
    position: relative;
    padding: 40px 0px 14px 0px;
    .logoWrapper {
      width: 110px;
    }
    .rightWrapper {
      width: calc(100% - 110px);
      flex-wrap: wrap;
      .lang {
        margin-right: 0px;
        margin-bottom: 8px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        padding: 10px 15px 0px 0px;
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
      }
      .notifyBtn {
        width: 34px;
        height: 34px;
        img {
          width: 13px;
        }
      }
      .userName {
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }
  .appointmentsPage {
    margin-top: 10px;
    margin-bottom: 40px;
  }
  .appointmentPHeader {
    .left {
      p {
        font-size: 16px;
        margin-right: 10px;
      }
      .plusBtn {
        width: 30px;
        height: 30px;
        &:before {
          width: 12px;
          height: 2px;
        }
        &:after {
          width: 2px;
          height: 12px;
        }
      }
    }
    .right {
      .fWrapper {
        .formRow {
          width: 100%;
          margin-bottom: 14px;
        }
      }
    }
  }
  .appointmentTabs {
    margin-top: 20px;
    .aTabs {
      li {
        margin-right: 8px;
        margin-bottom: 10px;
        button {
          font-size: 14px;
        }
      }
    }
  }
  .appointmentBox {
    padding: 20px;
    border-radius: 14px;
    .topHeader {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      .right {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 8px;
        .statusBadge {
          margin-bottom: 10px;
        }
        .idNumber {
          margin-top: 0px;
          margin-bottom: 10px;
        }
      }
    }
    .fWrapper {
      .doubleRow {
        .formRow {
          .datetimeDBWrapper {
            .formRow {
              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
    .apBoxBtns {
      .apBoxBtn {
        font-size: 14px;
        padding: 7px 20px;
      }
    }
  }
  .newAppoinmentForm {
    .doubleRow {
      max-width: calc(100% + 20px);
      & > div {
        width: 100%;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .formTitle {
      font-size: 18px;
      padding-left: 18px;
      &::before {
        width: 12px;
        height: 12px;
      }
    }
    button[type="submit"] {
      font-size: 16px;
      padding: 7px 24px;
      margin-top: 30px;
    }
  }
  .newAppoinmentForm .doubleRow > div .form-label {
    position: static;
    font-size: 14px;
    margin-bottom: 5px;
  }
}

.loaderBtn.loaderActive {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: "";
    width: 20px;
    height: 20px;
    background-image: url("../src/assets/images/btnLoader.svg");
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: 8px;
    -webkit-animation: 1s spinLoader linear infinite;
    animation: 1s spinLoader linear infinite;
  }
}

.siteLoader {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3;
  display: none;
  &.active {
    display: block;
  }
  .siteLoaderImg {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 70px;
      height: 70px;
      object-fit: contain;
      object-position: center;
      display: inline-block;
      -webkit-animation: 1s spinLoader linear infinite;
      animation: 1s spinLoader linear infinite;
    }
  }
}
@keyframes spinLoader {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// over writing bootstrap css

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  background-image: none;
  border: 2px solid var(--lightGray);
}

// 27-07-2022
body[dir="rtl"] {
  .header {
    .rightWrapper {
      padding-right: 10px;
      padding-left: 0;
      .userName {
        margin-left: 30px;
        margin-right: 0;
        @media (max-width: 767px) {
          margin-left: 15px;
        }
        @media (max-width: 575px) {
          margin-left: 10px;
        }
      }
    }
    .lang {
      margin-right: 0;
      margin-left: 20px;
      button {
        img {
          margin-right: 0;
          margin-left: 5px;
        }
        &.en {
          margin-right: 0;
          margin-left: 10px;
        }
        &.ar {
          margin-left: 0;
          margin-right: 10px;
        }
      }
    }
  }
  .newAppoinmentForm {
    .innerWrapper {
      margin: 0 0 auto;
      input {
        text-align: right;
      }
    }
    .formTitle {
      padding-right: 24px;
      padding-left: 0;
      &::before {
        right: 0;
        left: initial;
        transform: rotate(225deg);
      }
    }
    .doubleRow > div .form-label {
      left: initial;
      right: 30px;
    }
    button[type="submit"] {
      margin-right: 0;
    }
  }
  .appointmentPHeader {
    .right {
      padding-left: 0;
      padding-right: 30px;
      input {
        text-align: right;
      }
      // input[type="date"]::-webkit-calendar-picker-indicator {position: relative;left: -100px;}
      @media (max-width: 767px) {
        padding-right: 0px;
      }
    }
    .left {
      p {
        margin-right: 0;
        margin-left: 18px;
        @media (max-width: 575px) {
          margin-left: 10px;
        }
      }
    }
  }
  .form-select {
    background-position: left 0.75rem center;
  }
  .appointmentTabs {
    .aTabs {
      li {
        margin-right: 0;
        margin-left: 20px;
        @media (max-width: 767px) {
          margin-left: 10px;
        }
        @media (max-width: 575px) {
          margin-left: 8px;
        }
        &:last-child {
          margin-left: 0px;
        }
        button {
          display: flex;
          align-items: center;
          > span {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .appointmentBox {
    .topHeader {
      .left {
        padding-right: 0;
        padding-left: 30px;
      }
    }
    .apBoxBtns {
      .apBoxBtn {
        margin-right: 0;
        margin-left: 30px;
        @media (max-width: 767px) {
          margin-left: 15px;
        }
        &:last-child {
          margin-left: 0px;
        }
      }
    }
    // id number margin and padding in arabic
    .idNumber {
      text-align: left;
      margin-left: 10px;
    }
  }
  .fWrapper {
    .doubleRow {
      .formRow {
        input {
          text-align: right;
        }
      }
    }
  }
  .appointmentBox {
    .topHeader {
      .right {
        text-align: left;
      }
    }
  }
  .table_style_1 {
    .table {
      thead tr th,
      tbody tr td {
        &:last-child {
          border-right: 2px solid var(--lightGray);
        }
      }
      thead tr th,
      tbody tr td {
        &:first-child {
          border-right: none;
        }
      }
      .apBoxBtns {
        .apBoxBtn {
          margin-right: 0px;
          margin-left: 20px;
          &:last-child {
            margin-left: 0px;
          }
        }
      }
    }
  }
}

.picker-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.custom-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
body {
  .Toastify__toast {
    border-radius: 40px;
  }
  .Toastify__close-button {
    top: 0;
    position: relative;
    right: 10px;
  }
}

@media (max-width: 575px) {
  body {
    .Toastify__toast {
      // border-radius: 40px;
      width: 70%;
      height: 5%;
      margin: auto;
    }
    .Toastify__close-button {
      top: 0;
      position: relative;
      right: 10px;
    }
  }
}

//--
.table_style_1 {
  background: var(--white);
  box-shadow: 0px 0px 26px rgba(65, 3, 95, 0.09);
  border-radius: 22px;
  .table {
    width: 100%;
    border-color: transparent;
    white-space: nowrap;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    // line-height: 19px;
    color: var(--black);
    border-spacing: 0 1em;
    // border-collapse: separate;

    &.table-bordered > :not(caption) > * > * {
      border-width: 0px;
    }

    .tbl_details {
      width: 400px;
      white-space: initial;
      min-width: 400px;
    }
    thead tr th,
    tbody tr td {
      // border-right: 2px solid var(--lightGray);
      // &:last-child{
      //   border-right: none;
      // }
    }
    thead tr th {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: var(--gray700);
      padding: 15px;
    }
    tbody {
      tr {
        // &:last-child{
        //   td{
        //     border-bottom: none;
        //   }
        // }
        td {
          border-bottom: 2px solid var(--lightGray) !important;
          border-top: 2px solid var(--lightGray) !important;
          padding: 8px 15px;
          vertical-align: middle;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
    .statusBadge {
      font-size: 14px;
      padding: 5px 19px;
      display: inline-block;
      background-color: var(--statusBadgeGray);
      border-radius: 50px;
      color: var(--white);
      &.code1 {
        background-color: var(--statusBadgeDraft);
      }
      &.code2 {
        background-color: var(--statusBadgeNew);
      }
      &.code3 {
        background-color: var(--statusBadgeApproved);
      }
      &.code4 {
        background-color: var(--statusBadgeRejected);
      }
      &.code5 {
        background-color: var(--statusBadgeGray); // default
      }
      &.code6 {
        background-color: var(--statusBadgeReady);
      }
      &.code7 {
        background: var(--statusBadgeCall);
      }
      &.code8 {
        background-color: var(--statusBadgeGray); //default
      }
    }
    .apBoxBtns {
      .apBoxBtn {
        font-size: 16px;
        background: var(--grdBtn);
        color: var(--white);
        font-family: var(--rubikMedium);
        border: none;
        padding: 10px 23px;
        border-radius: 100px;
        transition: 0.2s;
        margin-right: 20px;
        &:last-child {
          margin-right: 0px;
        }
        &:hover {
          background: var(--grdBtnHover);
        }
      }
    }
  }
}

.select_field_input {
  label {
    display: block;
    position: relative;
    padding: 0px 0px 10px 30px;
    cursor: pointer;
    input {
      display: none;
      &:checked {
        + span {
          &::before {
            background: var(--statusBadgeCall);
          }
          &::after {
            content: "";
            left: -23px;
            top: 0px;
            width: 6px;
            height: 11px;
            border: solid var(--white);
            border-width: 0 2px 2px 0;
            transform: rotate(40deg);
            position: absolute;
          }
        }
      }
    }
    span {
      position: relative;
      &::before {
        content: "";
        width: 20px;
        height: 20px;
        background: lightgrey;
        position: absolute;
        left: -30px;
        top: -2px;
        border-radius: 0.25rem;
      }
    }
  }
}
.plusBtn {
  width: 44px;
  height: 44px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  background-color: var(--lightGray);
  border-radius: 50%;
  border: none;
  &::before,
  &::after {
    display: none;
  }
  textarea:focus {
    width: 2px;
    height: 16px;
  }
  img {
    width: 30px;
  }
}
.popover-arrow {
  &::before,
  &::after {
    display: none !important;
  }
}

.aTabsContantTarget {
  .scrollbar-container {
    max-height: 550px;
  }
}
